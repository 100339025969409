import {Form, Modal} from "react-bootstrap";
import {ReactElement} from "react";
import {AdminUserDetail} from "@/models/Admin/User";
import moment from "moment";

interface AUserDetailModalProps {
    adminUserDetail: AdminUserDetail | null;
}

const UserDetailModal = ({adminUserDetail}: AUserDetailModalProps) : ReactElement => {
    return (
        <>
            <Modal.Header closeButton>
                <Modal.Title>UserDetails</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {adminUserDetail ? (
                    <div>
                        <div>
                            <Form>
                                <Form.Group controlId="formUuid">
                                    <Form.Label>Uuid</Form.Label>
                                    <Form.Control
                                        type="string"
                                        name="Uuid"
                                        readOnly={true}
                                        value={adminUserDetail.uuid}
                                        //onChange={handleInputChange}
                                        placeholder="Enter user uuid"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formName">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                        type="string"
                                        name="Name"
                                        value={adminUserDetail.name}
                                        //onChange={handleInputChange}
                                        placeholder="Enter user name"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="string"
                                        name="Email"
                                        value = {adminUserDetail.email}
                                    />
                                </Form.Group>
                                <Form.Group controlId="formRole">
                                    <Form.Label>Role</Form.Label>
                                    <Form.Control
                                        type="string"
                                        name="Role"
                                        value={adminUserDetail.role === 0 ? "User" : "Admin" }
                                        readOnly={true}
                                        //onChange={handleInputChange}
                                        placeholder="Enter user role"
                                    />
                                </Form.Group>
                                <Form.Group controlId="formCreatedAt">
                                    <Form.Label>Created at</Form.Label>
                                    <Form.Control
                                        type="string"
                                        name="CreatedAt"
                                        readOnly={true}
                                        value = {
                                            moment(adminUserDetail.createdAt).format('DD/MM/YY')
                                        }
                                    />
                                </Form.Group>
                                <Form.Group controlId="formLoginAttempts">
                                    <Form.Label>Login Attempts</Form.Label>
                                    <Form.Control
                                        type="number"
                                        name="Login Attempts"
                                        value = {adminUserDetail.loginAttempts}
                                    />
                                </Form.Group>
                            </Form>
                        </div>
                    </div>
                ) : (
                    <div className="text-center p-4">
                        No user data available
                    </div>
                )}
            </Modal.Body>
        </>
    )
}

export default UserDetailModal;