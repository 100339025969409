import {ReactElement, useState} from 'react';

// @ts-ignore
import InfoIcon from "@/img/icon/info.svg"

interface TooltipIconProps {
    text: string | undefined;
}

const TooltipIcon = ({ text }: TooltipIconProps): ReactElement | null => {
    const [isVisible, setIsVisible] = useState(false);

    if (!text) return null;

    return (
        <span
            className='tooltip-container'
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
        >
            <img
                src={InfoIcon}
                alt="info"
                width="16"
                height="16"
                className='tooltip-icon'
            />
            {isVisible && (
                <div className='tooltip-content'>
                    <div className='tooltip-arrow'/>
                    <span className='tooltip-text'/>
                        {text}
                    <span/>
                </div>
            )}
        </span>
    );
};

export default TooltipIcon;
