import React, {ReactElement, useEffect, useState} from "react";
import Layout from "@/components/Layout";
import {Table, Button, Modal} from "react-bootstrap";
import {useApi} from "@/hoc/Api/context";
import {AdminUserInfo, AdminUserDetail} from "@/models/Admin/User";

// @ts-ignore
import ImpersonationIcon from "@/img/icon/impersonation_icon.svg";
import UserDetailModal from "@/components/Admin/UserDetailModal";
import Moment from "react-moment";
import {useDebounce} from "use-debounce";
// @ts-ignore

const Users = ({...props}): ReactElement => {

    const pageSize = 10;

    const api = useApi();
    const [users, setUsers] = useState<AdminUserInfo[]>([]);
    const [page, setPage] = useState(0);
    const [q, setQuery] = useState('');
    const [selectedUser, setSelectedUser] = useState<AdminUserDetail | null>(null);
    const [showModal, setShowModal] = useState(false);

    const [debouncedSearch] = useDebounce(q, 300)

    useEffect(() => {
        getUsersList()
    }, [debouncedSearch, page])

    const handleShowModal = (value: boolean) => {
        setShowModal(value);
    }

    const getUsersList = () => {
        api.GetUsersList(debouncedSearch, pageSize, page * pageSize).then(users => {
            setUsers(users);
        });
    }
    const onSearchChange = (value: string) => {
        setQuery(value);
    }

    const handlePreviousTablePage = () => {
        if (page > 0) {
            setPage(page - 1);
        }
    }

    const handleNextTablePage = () => {
        if (users.length >= pageSize) {
            setPage(page + 1);
        }
    }

    const handleUserSelect = (uuid: string) => {
        setShowModal(true);
        api.GetUserDetail(uuid).then(ud => {
            setSelectedUser(ud);
        });
    }

    const handleUserImpersonation = (uuid: string) => {
        api.ImpersonateUser(uuid).then(tok => {
            window.open(`/?impersonation=${tok.token}`, "_blank");
        });
    }

    return (
        <Layout>
            <div className='admin user container justify-content-center'>
                <h2 className='info-title'>Dashboard Utenti</h2>
                <div className='users table justify-content-center display-flex pt-3 pb-4'>
                    <div className='user-search-bar pt-3'>
                        <input
                            type="text"
                            placeholder="Search users by email or name..."
                            className="form-control border rounded pl-5 shadow-sm"
                            style={{
                                padding: '0.5rem 1rem',
                                borderColor: '#ddd',
                                backgroundColor: '#f9f9f9',
                                width: '100%',
                            }}
                            onChange={(e) => onSearchChange(e.target.value)}
                        />
                    </div>
                    <Table striped bordered hover>
                        <thead>
                        <tr className=''>
                            <th>UUID</th>
                            <th>Nome</th>
                            <th>Email</th>
                            <th>Creato il</th>
                            <th>Impersona</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            users.map((user) => {
                                return (
                                    <tr key={user.uuid}>
                                        <td>
                                            <Button
                                                variant="link"
                                                onClick={() => handleUserSelect(user.uuid)}
                                            >
                                                {user.uuid}
                                            </Button>
                                        </td>
                                        <td className={"text-center align-middle"}>{user.name}</td>
                                        <td className={"text-center align-middle"}>{user.email}</td>
                                        <td className={"text-center align-middle"}>
                                            <Moment format="DD/MM/YY" locale={"it"}>
                                                {user.createdAt}
                                            </Moment>
                                        </td>
                                        <td className="text-center">
                                            <Button
                                                variant="warning"
                                                title="Impersonate"
                                                style={{fontSize: "3rem"}}
                                                onClick={() => handleUserImpersonation(user.uuid)}
                                            >
                                                <img src={ImpersonationIcon} alt="ImpersonationIcon" width="25px"/>
                                            </Button>
                                        </td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                    <div className='d-flex justify-content-end pt-3'>
                        <Button
                            variant="outline-secondary"
                            onClick={handlePreviousTablePage}
                            disabled={page === 0}
                        >
                            Previous
                        </Button>
                        <Button
                            variant="outline-secondary"
                            onClick={handleNextTablePage}
                            disabled={!(users.length === pageSize)}
                        >
                            Next
                        </Button>
                    </div>
                    {
                        selectedUser
                            ?
                            <Modal
                                show={showModal}
                                onHide={() => {
                                    setShowModal(false);
                                }}
                                size="lg"
                            >
                                <UserDetailModal adminUserDetail={selectedUser}/>
                            </Modal>
                            : null
                    }
                </div>
            </div>
        </Layout>
    )
}


export default Users