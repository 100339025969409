import {ReactElement, useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {useApi} from "@/hoc/Api/context";

const ImpersonationBanner = () : ReactElement | null => {

    const api = useApi();

    const isImpersonating = api.IsImpersonating()

    const exitImpersonation = () => {
        window.close();
    }

    if (!isImpersonating) {
        return null;
    }

    return (
        <div className="impersonation-banner">
            <div className="banner-container">
        <span className="user-info">
            {`Impersonating ${api.IsLogged()?.email}`}
        </span>
                <button className="exit-button" onClick={exitImpersonation}>
                    <span className="close-icon">✕</span>
                    Exit
                </button>
            </div>
        </div>
    );
}

export default ImpersonationBanner