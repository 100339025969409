import Layout from '@/components/Layout'
import { useApi } from '@/hoc/Api/context'
import { UserRole } from '@/models/AuthUser'
import MembershipCardValidation from '@/models/MembershipCardValidation'
import { format } from 'date-fns'

import { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'


const Validazioni = ({ ...props }): ReactElement => {

    const [validation, setValidation] = useState<MembershipCardValidation>()
    const api = useApi()
    const authUser = api.IsLogged()
    const { id } = useParams()
    const navigate = useNavigate()

    useEffect(() => {
        if ( !authUser || authUser.role !== UserRole.ADMIN) {
            navigate('/', { replace: true })
        } else {
            api.GetSingleValidation(id!).then((r: MembershipCardValidation) => {
                setValidation(r)
            })
        }
    }, [])

    return (
        <Layout>
            <div className='validation container'>
                <h2 className='info-title mb-5'>Dettaglio Validazione</h2>
                <div className='d-flex flex-column gap-3'>
                    <div className='row'>
                        <div className='col'>
                            <b>Id:</b> {validation?.uuid}
                        </div>
                        <div className='col'>
                            <b>Totale:</b> {validation?.count}
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col'>
                            <b>Iniziata il:</b> {validation?.startedAt ? format(new Date(validation?.startedAt), 'dd-MM-yyyy hh:mm') : '-'}
                        </div>
                        <div className='col'>
                            <b>Finita il:</b> {validation?.endedAt ? format(new Date(validation.endedAt), 'dd-MM-yyyy hh:mm') : '-'}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <b>Creata il:</b> {validation?.createdAt ? format(new Date(validation?.createdAt), 'dd-MM-yyyy hh:mm') : '-'}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col'>
                            <b>Logs: </b>
                            {
                                validation?.logs && validation.logs.length > 0 ?
                                    <ul>
                                        {validation?.logs.map(log => <li>{log}</li>)}
                                    </ul> :
                                    "non ci sono messaggi"
                            }
                        </div>
                    </div>
                </div>
            </div>
        </Layout >

    )
}

export default Validazioni
