import 'bootstrap'
import './sass/main.scss'
import {createBrowserRouter, Navigate, Outlet, Route, RouterProvider, Routes} from 'react-router-dom'

import Auth from './pages/Auth/Auth'
import Carrello from './pages/Carrello/Carrello'
import Checkout from './pages/Checkout/Checkout'
import Faq from './pages/Faq/Faq'
import Home from './pages/Home/Home'
import Pdp from './pages/Pdp/Pdp'
import Plp from './pages/Plp/Plp'
import NotFound404 from './pages/404/NotFound404'
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy'
import Profile from './pages/Profile/Profile'
import TermsAndConditions from './pages/TermsAndConditions/TermsAndConditions'
import ApiProvider from "@/hoc/Api/provider";
import CheckoutCompleted from "@/pages/CheckoutCompleted/CheckoutCompleted";
import StoreProvider from "@/hoc/Store/provider";
import SeoProvider from "@/hoc/Seo/provider";
import {TeamProvider} from "@/hoc/Team/TeamProvider";
import {CookiesProvider} from "@/hoc/Cookies/Cookies";
import Validazioni from './pages/Validazioni/Validationi'
import React from "react";
import Validation from "@/pages/Admin/Validation";
import Users from "@/pages/Admin/Users";
import LoginGuard from "@/components/LoginGuard";

const App = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <ApiProvider baseUrl={process.env.REACT_APP_API_BASE_PATH ?? "https://sampdoria.ticketag.dev/api"}>
                <Outlet/>
            </ApiProvider>,
            children: [
                {
                    path: '',
                    element: <Home/>
                },
                {
                    path: '/Partite',
                    element: <Plp/>
                },
                {
                    path: '/Partita/:id',
                    element: <Pdp/>
                },
                {
                    path: '/Auth/*',
                    element: <Auth/>
                },
                {
                    path: '/Profilo/*',
                    element: <LoginGuard><Profile/></LoginGuard>
                },
                {
                    path: '/Carrello',
                    element: <LoginGuard><Carrello/></LoginGuard>
                },
                {
                    path: '/Checkout/:id',
                    element: <LoginGuard><CheckoutCompleted/></LoginGuard>
                },
                {
                    path: '/Checkout',
                    element: <LoginGuard><Checkout/></LoginGuard>
                },
                {
                    path: '/Termini-e-Condizioni',
                    element: <TermsAndConditions/>
                },
                {
                    path: '/Privacy-Policy',
                    element: <PrivacyPolicy/>
                },
                {
                    path: '/Faq',
                    element: <Faq/>
                },
                {
                    path: '/admin',
                    element: <LoginGuard>
                        <Outlet/>
                    </LoginGuard>,
                    children: [
                        {
                            path: '',
                            element: <Navigate to={'/admin/users'}/>
                        },
                        {
                            path: 'users',
                            element: <Users/>,
                        },
                        {
                            path: 'validation',
                            element: <Validation/>,
                        }
                    ]
                },
                {
                    path: '/Validazioni/:id',
                    element: <LoginGuard><Validazioni/></LoginGuard>
                },
                {
                    path: '*',
                    element: <NotFound404/>
                }
            ]
        }
    ])
    return (
        <TeamProvider eventIssuer={process.env.REACT_APP_EVENT_ISSUER || undefined}>
            <StoreProvider>
                <CookiesProvider>
                    <SeoProvider>
                        <RouterProvider router={router}/>
                    </SeoProvider>
                </CookiesProvider>
            </StoreProvider>
        </TeamProvider>

    )
}

export default App
