import React, {ReactElement, useEffect, useState} from "react";
import EventIssuer, {CardDetails, PinType} from "@/models/EventIssuer";
import Loader from "@/components/Loader";
import {useApi} from "@/hoc/Api/context";
import ReductionCode from "@/models/ReductionCode";
import {AddCardFormData} from "@/components/AddCardModal/AddCardModal";
import {useForm} from "react-hook-form";
import Select, {StylesConfig} from "react-select";
import DatePickerComponent from "@/components/DatePickerComponent/DatePickerComponent";
import {useTeam} from "@/hoc/Team/TeamProvider";
import variables from "@/models/ColorsVariables";
import TooltipIcon from "@/components/TooltipIcon";

interface AddCardFormProps {
    onComplete: (data: AddCardFormData) => void;
    eventIssuer?: EventIssuer | undefined;
    initialData?: AddCardFormData;
}

interface selectEventIssuerOption {
    value: EventIssuer;
    label: string;
    cardDetails: CardDetails;
}

interface selectReductionCodeOption {
    value: ReductionCode;
    label: string;
}

const AddCardForm = (props: AddCardFormProps): ReactElement => {

    const api = useApi()
    const teamProvider = useTeam();

    const [eventIssuers, setEventIssuers] = useState<EventIssuer[] | undefined>(undefined);
    const [reductionCodes, setReductionCodes] = useState<ReductionCode[] | undefined>([])

    const [issuerOptions, setIssuerOptions] = useState<selectEventIssuerOption[]>([]);
    const [reductionCodeOptions, setReductionCodeOptions] = useState<selectReductionCodeOption[]>([]);

    const [reductionCodeValueOptions, setReductionCodeValueOptions] = useState<selectReductionCodeOption | null>();

    const {handleSubmit, register, formState, setValue, getValues, trigger} = useForm<AddCardFormData>({
        mode: "onBlur", defaultValues: props.initialData || {
            firstName: "",
            lastName: "",
            eventIssuer: props.eventIssuer,
            cardNumber: "",
            pin: "",
            reductionCode: undefined
        }
    });

    const getReductionCode = (ev: any, uuid?: string): void => {
        setReductionCodeOptions([]);
        api.GetEventIssuerReductionCodes(ev ? ev.value.uuid : uuid ? uuid : "")
            .then(r => {
                let reductionCodes: selectReductionCodeOption[] = [];
                for (const reductionCode of r) {
                    reductionCodes.push({value: reductionCode, label: reductionCode.name})
                }
                setReductionCodeOptions(reductionCodes);
                setReductionCodes(r)
            })
    }

    useEffect(() => {
        register("reductionCode", {required: true, validate: (value) => value !== undefined});
        register("eventIssuer", {required: true, validate: (value) => value !== undefined});
        register("pin", {required: true, minLength: 3});

        if (getValues("eventIssuer")) {
            getReductionCode(undefined, getValues("eventIssuer.uuid"))
        }

        api.GetActiveEventIssuers().then(r => {
            let issuerOptions: selectEventIssuerOption[] = [];
            let teamProviderEventIssuer = r
            for (const eventIssuer of r) {
                issuerOptions.push({value: eventIssuer, label: eventIssuer.name, cardDetails: eventIssuer.cardDetails})
            }
            if (teamProvider.GetEventIssuer() !== undefined) {
                issuerOptions = issuerOptions.filter(i => i.value.uuid === teamProvider.GetEventIssuer())
                teamProviderEventIssuer = r.filter(i => i.uuid === teamProvider.GetEventIssuer())
                setValue("eventIssuer", teamProviderEventIssuer[0])
                getReductionCode(null, teamProvider.GetEventIssuer())
            }
            setIssuerOptions(issuerOptions)
            setEventIssuers(teamProviderEventIssuer)
        })
    }, [])

    if (eventIssuers === undefined || reductionCodes === undefined) {
        return <Loader/>
    }

    const selectStyles: StylesConfig = {
        control: (styles) => ({...styles, backgroundColor: variables.background, border: `1px solid ${variables.linesAndBox}`, boxShadow: "none", color: variables.mainText, fontSize: 14}),
        indicatorSeparator: (styles) => ({ ...styles, display: 'none'}),
        valueContainer: (styles) => ({ ...styles, color: variables.mainText}),
        placeholder: (styles) => ({ ...styles, color: variables.mainText}),
        dropdownIndicator: (styles, {isFocused}) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover' : {
                    color: variables.mainText
                }
            }
        },
        clearIndicator: (styles, {isFocused}) => {
            return {
                ...styles,
                color: isFocused ? variables.mainText : variables.mainText,
                ':hover' : {
                    color: variables.mainText
                }
            }
        },
        option: (styles, {isFocused, isSelected}) => {
            return {
                ...styles,
                cursor: 'pointer',
                backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                color: variables.mainText,
                fontSize: 14,
                ':active': {
                    ...styles[':active'],
                    backgroundColor: isFocused ? variables.linesAndBox : isSelected ? variables.linesAndBox : variables.background,
                },
            }
        },
        singleValue: (styles) => ({ ...styles, color: variables.mainText })
    }

    return (
        <form id="aggiungi-tessera-form"
              className="ticket-cards__generic-form ticket-cards__modal__content__form needs-validation mt-4"
              noValidate onSubmit={handleSubmit(props.onComplete)}
        >
            <div className="row px-0 px-lg-4 gy-3">
                {/* NAME */}
                <div className="form-group col-12 col-md-5 col-lg-4 offset-md-1 offset-lg-0 py-2 my-sm-3">
                    <label htmlFor="Nome">{"Nome"}</label>
                    <input type="text" className={`form-control ${formState.errors.firstName ? "is-invalid" : ""}`}
                           style={{height: "38px"}}
                           id="Nome"
                           {...register("firstName", {required: true})}
                    />
                    <div className="invalid-feedback">{"Campo richiesto"}</div>
                </div>

                {/* SURNAME */}
                <div className="form-group col-12 col-md-5 col-lg-4 py-2 my-sm-3">
                    <label htmlFor="Cognome">{"Cognome"}</label>
                    <input type="text" className={`form-control ${formState.errors.lastName ? "is-invalid" : ""}`}
                           style={{height: "38px"}}
                           id="Cognome" required
                           {...register("lastName", {required: true})}
                    />
                    <div className="invalid-feedback">{"Campo richiesto"}</div>
                </div>

                {/* TEAM */}
                <div className="form-group col-12 col-md-5 col-lg-4 offset-md-1 offset-lg-0 py-2 my-sm-3">
                    <label htmlFor="team">{"Squadra"}</label>
                    <Select
                        defaultValue={getValues("eventIssuer") ? issuerOptions.filter(
                            (issuerOption) => issuerOption.value.uuid === getValues("eventIssuer")?.uuid) : null}
                        options={issuerOptions}
                        placeholder={"Seleziona"}
                        styles={selectStyles}
                        className={`${formState.errors.eventIssuer ? "is-invalid" : ""}`}
                        onChange={(ev:any) => {
                            setReductionCodeValueOptions(null)
                            setValue("reductionCode", undefined, {shouldValidate: true})
                            setValue("eventIssuer", ev.value, {shouldValidate: true})
                            setValue("pin", "", {shouldValidate: false})
                            setReductionCodes([])
                            getReductionCode(ev)
                        }}
                    />
                    <div id="teamError" className="invalid-feedback">{"Errore"}</div>
                </div>

                {/* TESSERA CODE */}
                <div className="form-group col-12 col-md-5 col-lg-4 py-2 my-sm-3">
                    <label htmlFor="FidelityCard">
                        {
                            getValues("eventIssuer")?.cardDetails.cardNumber.label.toUpperCase() ?? "Tessera"
                        }
                        {getValues("eventIssuer") && <TooltipIcon text={getValues("eventIssuer")?.cardDetails.cardNumber.tip}/>}
                    </label>
                    <input type="text"
                           className={`form-control ${formState.errors.cardNumber ? "is-invalid" : ""}`}
                           style={{height: "38px"}}
                           {...register("cardNumber",
                               {
                                   required: true,
                                   disabled: getValues("eventIssuer") === undefined,
                                   pattern: {
                                       value: new RegExp(getValues("eventIssuer")?.cardDetails.cardNumber.validator ?? ""),
                                       message: getValues("eventIssuer")?.cardDetails.cardNumber.errorMsg || ""
                                   }
                               })}
                    />
                    {formState.errors.cardNumber && (
                        <div className="invalid-feedback" style={{display: "block"}}>
                            {formState.errors.cardNumber.message}
                        </div>
                    )}
                </div>

                {/* TESSERA PIN */}
                <div className="form-group col-12 col-md-5 col-lg-4 offset-md-1 offset-lg-0 py-2 my-sm-3">
                    <label htmlFor="pin">
                        {
                            getValues("eventIssuer")?.cardDetails.pin.label.toUpperCase() ?? "PIN"
                        }
                        {getValues("eventIssuer") && <TooltipIcon text={getValues("eventIssuer")?.cardDetails.pin.tip}/>}
                    </label>
                    {
                        (getValues("eventIssuer")?.cardDetails.pinType === PinType.DATE)
                            ?
                            (
                                <DatePickerComponent
                                    initialData={
                                        props.initialData?.eventIssuer?.cardDetails.pinType === PinType.DATE ?
                                            props.initialData?.pin ? new Date(props.initialData?.pin) : undefined
                                            :
                                            undefined
                                    }
                                    onSelect={(date: Date | undefined) => {
                                        setValue('pin', date?.toDateString() || "", {shouldValidate: true})
                                    }}
                                />
                            )
                            :
                            (getValues("eventIssuer")?.cardDetails.pinType === PinType.PIN) ?
                                (
                                    <input type="text"
                                           {...register("pin",
                                               {
                                                   required: true,
                                                   pattern: {
                                                       value: new RegExp(getValues("eventIssuer")?.cardDetails.pin.validator ?? ""),
                                                       message: getValues("eventIssuer")?.cardDetails.pin.errorMsg || ""
                                                   },
                                               }
                                           )}
                                           style={{height: "38px"}}
                                           className={`form-control ${formState.errors.pin ? "is-invalid" : ""}`}
                                           id="pin"
                                    />
                                ):
                                (
                                    <input type="text"
                                           style={{height: "38px"}}
                                           className={"form-control"}
                                           disabled
                                    />
                                )
                    }
                    {formState.errors.pin && (
                        <div className="invalid-feedback" style={{display: "block"}}>
                            {formState.errors.pin?.message}
                        </div>
                    )}
                </div>

                {/* TESSERA TYPOLOGY */}
                <div className="form-group col-12 col-md-5 col-lg-4 py-2 my-sm-3">
                    <label htmlFor="reductionCode">{"Tipologia"}</label>
                    <Select
                        defaultValue={getValues("reductionCode") ? reductionCodeOptions.filter(
                            (reductionCode) => reductionCode.value.uuid === getValues("reductionCode.uuid")) : null}
                        value={reductionCodeValueOptions}
                        options={reductionCodeOptions}
                        className={`${formState.errors.eventIssuer ? "is-invalid" : ""}`}
                        placeholder={"Seleziona"}
                        styles={selectStyles}
                        onChange={(ev:any) => {
                            setReductionCodeValueOptions({
                                value: ev.value,
                                label: ev.label
                            })
                            setValue("reductionCode", ev.value, {shouldValidate: true})
                        }}
                    />
                    <div className="invalid-feedback">{"Errore"}</div>
                </div>

                <div className="col-12 d-flex justify-content-center pt-5 pt-md-4 py-4">
                    <button type="submit"
                            disabled={!formState.isValid || getValues("pin") === ""}
                            className="ticket-cards__modal__content__compile-options__btn-continue w-100 btn btn--gradient"
                    >
                        Avanti
                    </button>
                </div>

            </div>
        </form>
    )
}

export default AddCardForm
