import {ReactElement, ReactNode, useEffect, useState} from "react";
import {useApi} from "@/hoc/Api/context";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import Loader from "@/components/Loader";

interface LoginGuardProps {
    children: ReactNode;
}

const LoginGuard = ({children}: LoginGuardProps): ReactElement => {
    const api = useApi()
    let location = useLocation();

    return <>
        {api.IsLogged() !== null ?
            children :
            <Navigate to={`/Auth?from=${location.pathname}`}/>
        }
    </>
}

export default LoginGuard;
