export enum PinType {
    DATE = 'date',
    PIN = 'code',
}

export default interface EventIssuer {
    uuid: string;
    name: string;
    color1: string;
    color2: string;
    logo: string;
    mainLocationUuid: string;
    cardDetails: CardDetails
}

export interface CardDetails {
    cardNumber: CardField;
    pinType: string;
    pin: CardField;
}

export interface CardField {
    validator: string;
    label: string;
    tip: string;
    errorMsg: string;
}
